body {
    margin: 0;
    padding: 0;
}

.MuiAlert-message {
    overflow: hidden !important;
}

#root {
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
}

.animated-sun {
    width: 100%;
    height: 100%;
    background-color: #FFDE00;
    border-radius: 50%;
    box-shadow:
        0 0 0 20px #FFDE0080,
        0 0 0 40px #FFDE0040,
        0 0 0 60px #FFDE0020,
        0 0 0 80px #FFDE0010,
        0 0 0 100px #FFDE0000,
        0 0 40px 100px #FFDE0010;
    animation:
        sunrise 2s infinite linear forwards,
        rays 2s 2s infinite linear;
}

@keyframes sunrise {
    0% {
        box-shadow: none;
    }
}

@keyframes rays {
    0% {
        box-shadow: 
        0 0 0 0 #FFDE0080,
        0 0 0 20px #FFDE0080,
        0 0 0 40px #FFDE0040,
        0 0 0 60px #FFDE0020,
        0 0 0 80px #FFDE0010,
        0 0 40px 100px #FFDE0010;
    }
    100% {
        box-shadow: 
        0 0 0 20px #FFDE0080,
        0 0 0 40px #FFDE0040,
        0 0 0 60px #FFDE0020,
        0 0 0 80px #FFDE0010,
        0 0 0 100px #FFDE0000,
        0 0 40px 100px #FFDE0010;
    }
}

.animated-cloud {
    background: rgba(245, 245, 245);
    width: 300px; 
    height: 100px;
    border-radius: 150px;	
    box-shadow: 10px 10px rgba(0,0,0,0.2);
    animation: moveClouds 7s infinite;
}
  
.animated-cloud:after {
    content: '';
    background: rgba(245, 245, 245);
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: -50px;
    left: 50px;
}
  
.animated-cloud:before {
    content: '';
    background: rgba(245, 245, 245);
    position: absolute;
    width: 170px;
    height: 150px;
    border-radius: 50%;
    top: -90px;
    right: 40px;
}
  
@keyframes moveClouds {
    0% {
        transform: translatex(0);
    }
    50% {
        transform: translatex(-10px);
    }
}
  
.snow {
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.2%3Bfill:%23069af3%3B%7D.st3%7Bopacity:0.1%3Bfill:%23069af3%3B%7D%3C/style%3E%3Ccircle class='st1' cx='5' cy='8' r='0.55'/%3E%3Ccircle class='st1' cx='38' cy='1' r='0.1'/%3E%3Ccircle class='st1' cx='12' cy='2' r='0.2'/%3E%3Ccircle class='st1' cx='16' cy='16' r='0.5'/%3E%3Ccircle class='st1' cx='47' cy='46' r='0.6'/%3E%3Ccircle class='st1' cx='32' cy='10' r='0.5'/%3E%3Ccircle class='st1' cx='3' cy='46' r='0.6'/%3E%3Ccircle class='st1' cx='45' cy='13' r='0.4'/%3E%3Ccircle class='st1' cx='10' cy='28' r='0.2'/%3E%3Ccircle class='st1' cx='22' cy='35' r='0.7'/%3E%3Ccircle class='st1' cx='3' cy='21' r='0.3'/%3E%3Ccircle class='st1' cx='26' cy='20' r='0.3'/%3E%3Ccircle class='st1' cx='30' cy='45' r='0.2'/%3E%3Ccircle class='st1' cx='15' cy='45' r='0.4'/%3E%3Ccircle class='st1' cx='34' cy='36' r='0.6'/%3E%3Ccircle class='st1' cx='41' cy='32' r='0.5'/%3E%3C/svg%3E");
    background-position: 0px 0px;
    animation: animatedSnow 15s linear infinite;
}

.snow div {
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.5%3Bfill:%23069af3%3B%7D.st3%7Bopacity:0.1%3Bfill:%23069af3%3B%7D%3C/style%3E%3Ccircle class='st3' cx='4' cy='14' r='0.6'/%3E%3Ccircle class='st3' cx='43' cy='3' r='0.5'/%3E%3Ccircle class='st3' cx='31' cy='30' r='0.5'/%3E%3Ccircle class='st3' cx='19' cy='23' r='0.4'/%3E%3Ccircle class='st3' cx='37' cy='22' r='1'/%3E%3Ccircle class='st3' cx='43' cy='16' r='0.6'/%3E%3Ccircle class='st3' cx='8' cy='45' r='0.2'/%3E%3Ccircle class='st3' cx='29' cy='39' r='1'/%3E%3Ccircle class='st3' cx='13' cy='37' r='0.4'/%3E%3Ccircle class='st3' cx='47' cy='32' r='0.1'/%3E%3Ccircle class='st3' cx='15' cy='4' r='0.8'/%3E%3Ccircle class='st3' cx='9' cy='27' r='0.8'/%3E%3Ccircle class='st3' cx='30' cy='9' r='0.3'/%3E%3Ccircle class='st3' cx='25' cy='15' r='0.3'/%3E%3Ccircle class='st3' cx='21' cy='45' r='0.3'/%3E%3Ccircle class='st3' cx='42' cy='45' r='1'/%3E%3C/svg%3E");
    background-position: 0px 0px;
    animation: animatedSnow 20s linear infinite;
}

@keyframes animatedSnow {
    0% {
        background-position: 0 0;
        opacity: 0;
    }

    10% {
        opacity: 0.7;
    }

    40% {
        background-position: 20px 60px;
        opacity: 1;
    }

    100% {
        background-position: -20px 150px;
        opacity: 0;
    }
}

.rain {
    height: 100%;
    background: url(/public/images/rain.png);
    animation: rain 0.3s linear infinite;
}

@keyframes rain {
    0% { background-position: 0px 0px; }
    100% { background-position: 150px 400px; }
}